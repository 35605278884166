@import "./aboutus-pages";
// AboutUs Page
#AboutUs {
  padding-top: $size2;
}
.aboutus-heading{
    margin-top: $size10;
    text-align: center
  }
  .aboutus-title{
    color: $color-08aeea
  }
  .aboutus-text,.aboutus-text2 {
    font-size: 1.3rem;
  }
  .aboutus-text2{
    font-weight: bold;
    font-size: 2.5rem;
    text-align: center;
    margin-bottom: 2rem;
  }
  .aboutus-image{
  width: 100%;
  padding: 0;
    }
  .col-lg-6{
    padding: 0
  }
  .aboutus-paragraph{
    text-align: left;
    font-size: $size1;
    line-height: $size2;
  }
  @media (max-width:992px){
    .container {
      padding: 0%;
    }
    // .mobile-aboutus {
    //    padding: 0 1rem;
    // }
    .mobile-about{
      display: flex;
      flex-direction: column-reverse;
    }
    .aboutus-heading{
      margin-top: $size1;
      padding-left: $size1;
    }
    .aboutus-paragraph{
      margin: $size2 0;
      text-align: center;
      background-color: #F9F9F9;
      padding: 2rem 1rem;
      // opacity: 0.5;
    }
    .aboutus-image {
      width:90%;
    }
    .aboutus-text {
      opacity: 0.5;
    }
    .mobile-aboutus {
      opacity: 0.5;
    }
  }