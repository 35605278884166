@import "./home-sub-pages";
// #Homepage
@mixin mainText{
    font-weight: bold;
    color: $color-1A1A1A;
}
.intro-section {
    text-align: $text-center;
}    
  .intro-heading {
      @include mainText();
      font-size: $size4;
      padding-top: $size6;
  }
  .intro-paragraph {
    color: $color-1A1A1A;
    font-size: $size2;
    line-height: $size3;
    margin: $size4; 
  }
  #intro-btn {
    font-size: 1.25rem;
    padding: 0.7rem 2.7rem;
    border-radius: 6px;
      &:hover{
       background-color: $color-6c63ff
        }
   }
  .intro-image {
    margin: $size2 0;
    width: 80%
  }
  @media (max-width:992px){
    .intro-section{
      display: flex;
      flex-direction: column-reverse;
    }
    .intro-heading{
        font-weight: bold;
        font-size: $size3;
        margin-top: 1.5rem;
        padding-top: 0;
    }  
    .intro-paragraph {    
        font-size: 1.5rem;
        line-height: $size2;
        margin: $size1;
        }
    .intro-image{
        width: 90%;
       } 
  }
  // #Footer
  .icons {
    margin: 0 0.8rem;
    width:20vw;

  }
  .copyright{
    margin-bottom: $size2;
  }
  .hyperlink{
    color: $color-1A1A1A;
  }
  .footerpara-heading{
    font-weight: bold;
  }
  @media (max-width:992px){
    .footer {
      text-align: $text-center;
    }
    .footerpara{
      padding-left: $size1;
    }
    #img-fluid {
      width:20% !important;
      margin:0 1rem;
    }
    .copyright {
      margin-bottom: $size2;
    }
    .icons {
      margin-top: 1rem;
    }
  }
  //terms and conditions
  .tos-links {
    list-style-type: none;
    padding-left: $size1;
  }
  #tos {
    padding-top: 4.5rem;
  }
  #privacy {
    padding-top: 4.5rem;
  }
  .privacy-header {
    font-weight: bold;
    margin-left: $size1;
  }

  .mobile-footer {
    text-align: center;
    background-color: #F9F9F9;
    padding-bottom: 1rem;
  }
  .about-links {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    text-decoration: underline;
    // margin: 1rem 0;
  }
  #mobile-address {
    margin: 0 auto;
    display: block;
    text-align: center !important;
  }
  .termsofservice {
    font-weight: 600;
    text-decoration: underline;
  }
  @media (max-width:992px){
    #tos,#privacy {
      padding-top: 0;
      margin: 0 $size1;
    }
    .privacy-header {
      font-weight: 400;
      margin-left: $size1;
    }
  }